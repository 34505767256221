import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import emailjs from '@emailjs/browser';
import logoCourses from '../../assets/logo/courses.png';

function AppContact() {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_qckwbs5', 'template_fydcc6u', e.target, 'GrkMRxBhKwuyKaKMr');
  }

  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">get connected with us</div>
        </div>
        <Form className='contact-form' onSubmit={sendEmail}>
          <Row>
            <Col sm={3}>
              <Form.Label className="form-label">First Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="First Name" required />
            </Col>
            <Col sm={3}>
              <Form.Label className="form-label">Last Name</Form.Label>
              <Form.Control type="text" name="lastname" placeholder="Last Name" required />
            </Col>
            <Col sm={3}>
              <Form.Label className="form-label">Email</Form.Label>
              <Form.Control type="email" name="email" placeholder="youremail@email.com" required />
            </Col>
            <Col sm={3}>
              <Form.Label className="form-label">Phone Number</Form.Label>
              <Form.Control pattern="^\+[0-9]+$" type="tel" name="number" placeholder="+963 000 000 000" required />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Label className="form-label">Age</Form.Label>
              <Form.Control pattern="^([1][7-9]|[2-2]\d|[3][2])$" type="text" name="age" placeholder="Only Between 17 & 32" required />
            </Col>
            <Col sm={4}>
              <Form.Label className="form-label">Certificate</Form.Label>
              <Form.Control type="text" name="certificate" placeholder="Certificate" required />
            </Col>
            <Col sm={4}>
              <Form.Label className="form-label">Graduation date</Form.Label>
              <Form.Control type="date" name="date" placeholder="Graduation date" required />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Label className="form-label">Your Message</Form.Label>
              <Form.Control as="textarea" name="message" placeholder="Enter your message here..." required />
            </Col>
          </Row>
          <div className='btn-holder'>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Container>
      <div className='google-map'>
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3356.71093150473!2d36.5648463!3d32.72030819999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15197f007f2bf1cd%3A0x341b918d5e342e5e!2z2KPZhdmEINin2YTZhdiz2KrZgtio2YQgKFN0dWR5IGluIFByYWd1ZSk!5e0!3m2!1sen!2scz!4v1728576351612!5m2!1sen!2scz"></iframe>
      </div>
      <Container fluid>
        <div className='contact-info'>
          <Row>
            <Col sm={6}>
              <h3 className='text-center fw-bold'>Syria</h3>
            </Col>            
            <Col sm={6}>
              <img className='logo' alt="logo" src={logoCourses} />
            </Col>
          </Row>
          <ul>
            <li className='m-1'>
              <i className="fas fa-envelope"></i>
              <a className='text-secondary' href= "mailto: language-amal@seznam.cz">language-amal@seznam.cz</a>
            </li>
            <li className='m-1'>
              <i className="fas fa-phone"></i>
              <a className='d-md-block text-secondary pe-pointer' href="tel:+963953684313">+963953684313 </a>
            </li>
            <li className='m-1'>
              <i className="fas fa-map-marker-alt"></i>
               <a className='text-secondary' href="https://maps.app.goo.gl/F3sx34sZjE6HZwox6" target="_blank" rel="noopener noreferrer">السويداء, شرق المحافظة - بناء المغتربين ط 2 <br/>As Suwayda, Syria</a>
            </li>
          </ul>
        </div>      
        <div className='contact-info'>
          <h3 className='text-center fw-bold'>Social Media</h3>
          <ul>
            <li>
              <a href="https://www.facebook.com/profile.php?id=61566504852607" target="_blank" rel="noopener noreferrer">
                <i className="fab fas fa-facebook-f"></i>
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/amal.almustaqbal" target="_blank" rel="noopener noreferrer">
                <i className="fab fas fa-instagram"></i>
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;