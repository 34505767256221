import AppHero from './hero';
import AppAbout from './about';
import AppServices from './services';
import Images from './images';
import AppTestimonials from './testimonials';
import HowItWorks from './howItWorks';
import AppContact from './contact';

function Home() {
  return (
    <>
      <AppHero />
      <AppAbout />
      <AppServices />
      <HowItWorks />
      <Images />
      <AppTestimonials />
      <AppContact />
    </>
  );
}

export default Home;
