import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-globe',
    title: 'English Courses',
    description: 'We can help improve all your English skills and get you on the right path to a successful future. Our courses are designed especially for people of your age and you’ll learn with students just like you. we are uniquely positioned to help you get the score you need to get you into your dream university.'
  },
  {
    id: 2,
    icon: 'fas fa-school',
    title: 'Deutsch Courses',
    description: 'German is one of the ten most spoken languages in the world. Some 185 million people worldwide speak German. You can still study in Germany even if you don’t know German, but some knowledge of the language makes everyday life a lot easier and helps you immerse yourself in the country.'
  },
  {
    id: 3,
    icon: 'fas fa-laptop-file',
    title: 'Czech Courses (Online)',
    description: 'Do you want to study Czech even at home? The aim of this e-learning is to support pupils of different ages who start to learn Czech as a second language. Here you can study vocabulary, pronunciation, grammar, and Czech in practice. The lessons and exercises cover various topics related to school, family, leisure time, etc.'
  }
]

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Our courses</h2>
          <div className="subtitle">courses we provide</div>
        </div>
        <Row className='text-center'>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder p-3' key={services.id}>
                    <div className="icon">
                      <i className={services.icon}></i>
                    </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;