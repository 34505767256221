import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/cz.png'),
    title: 'Study in Prague',
    description: 'There are 8 public universities in Prague together with 1 state college and 20 private universities. Therefore, the offer of study programmes is wide and everyone can find their branch of interest.',
    link: 'https://study-in-prague-app.vercel.app/'
  },
  {
    id: 2,
    image: require('../assets/images/r.png'),
    title: 'Study in Romania',
    description: 'Romania is a beautiful country with a rich culture, lively cities, and good education, which makes it a popular choice for students. The cost of living and tuition fees in Romania are usually lower than in other European countries.',
    link: 'https://study-in-prague-app.vercel.app/'
  },
  {
    id: 3,
    image: require('../assets/images/gr.png'),
    title: 'Study in Germany',
    description: 'Would you like to study in Germany? You can find scholarship offers, international programmes, language courses and much more on our Website.',
    link: 'https://study-in-prague-app.vercel.app/'
  }, 
  {
    id: 4,
    image: require('../assets/images/slovakia.png'),
    title: 'Study in Slovakia',
    description: 'The higher education system of Slovakia is represented by 21 universities with 482 study programs. In addition 169 Bachelor programs at 21 universities',
    link: 'https://study-in-prague-app.vercel.app/'
  },
  {
    id: 5,
    image: require('../assets/images/rm.png'),
    title: 'Work visa - Romania',
    description: 'Do you plan to live and work in Romania? You will need to acquire a work permit. We can help you to get your visa and find you a good job',
    link: 'https://study-in-prague-app.vercel.app/'
  } 
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption className='m-2'>
                    <h2>{hero.title}</h2>
                    <p className='s-remove'>{hero.description}</p>
                    <Button className="btn btn-danger" href={hero.link} target="_blank" rel="noopener noreferrer">Learn More <i className="fas fa-chevron-right"></i></Button>
                    {/* <a className="btn btn-primary" href={hero.link} target="_blank" rel="noopener noreferrer">Learn More <i className="fas fa-chevron-right"></i></a> */}
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;