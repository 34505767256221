import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-people-group',
    title: 'Career Counseling',
    description: 'We assist you to enroll in a present academic program which will help you to build up your Professional career once you finish your academic program.'
  },
  {
    id: 2,
    icon: 'fas fa-building-columns',
    title: 'University Selection',
    description: 'We represent a large number of well-reputed universities around the world. These universities are offering almost 500 programs for international students.'
  },
  {
    id: 3,
    icon: 'fas fa-language',
    title: 'Language Course',
    description: 'Our partner universities are providing appropriate English language courses to improve English Proficiency to study if needed.'
  },
  {
    id: 4,
    icon: 'fas fa-house-chimney-user',
    title: 'Accommodation',
    description: 'Our partner universities provide accommodation facilities at the dormitory. Besides this, you can take our off-campus partner housing facilities.'
  },
  {
    id: 5,
    icon: 'fas fa-file-pen',
    title: 'Visa Application Guide',
    description: 'We always assist students to submit their visa applications with genuine, perfect and supporting documents.'
  },
  {
    id: 6,
    icon: 'fas fa-car',
    title: 'Airport Pickup',
    description: 'Airport pickup is available to new international and interested students arriving at the Airport. A representative will meet you at the airport and take you directly to your accommodation.'
  }
]

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Our services</h2>
          <div className="subtitle">services we provide</div>
        </div>
        <Row className='text-center'>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder p-3' key={services.id}>
                    <div className="icon">
                      <i className={services.icon}></i>
                    </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;