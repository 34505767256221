import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AppAbout() {
  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row className="p-4 min-vh-45">
          <Col sm={6}>
              <iframe className="w-100 h-100" src="https://www.youtube.com/embed/kAStHuA-wX0?si=oe7Kz08edvcUT9Kv" title="video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </Col>
          <Col sm={6}>
            <p>We are a student consultancy ﬁrm. Our core value is to support and help students without compromising the quality because their choices about their future are so important. Our slogan is “Give us your trust to change your future”. We are doing career consultancy according to the student’s background. We stand as a leading education and career consultancy provider in Europe.</p>
          </Col>
        </Row>
        <Row className="p-4 min-vh-45">
        <Col sm={6}>
            <p>Our primary goal is to empower the upcoming generation of students by facilitating their journey to the world’s finest universities. Our dedicated team of experts is committed to assisting students throughout the entire process, from university selection and application guidance to interview preparation, visa processing, and support. Our unwavering commitment is to offer unparalleled support while maintaining the highest quality standards, recognizing the profound impact these decisions have on students’ futures.</p>
          </Col>
          <Col sm={6}>
          <iframe className="w-100 h-100" src="https://www.youtube.com/embed/09_tb6MkbLs?si=ZKO_UgQRR7N-XDKY" title="video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;