import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
// import Pagination from 'react-bootstrap/Pagination';

const worksData = [
  {
    id: 1,
    image: require('../../assets/images/larning1.jpg')
  },
  {
    id: 2,
    image: require('../../assets/images/larning2.jpg')
  },
  {
    id: 3,
    image: require('../../assets/images/larning3.jpg')
  }
]

// let active = 1;
// let items = [];
// for (let number = 1; number <= 5; number++) {
//   items.push(
//     <Pagination.Item key={number} active={number === active}>
//       {number}
//     </Pagination.Item>,
//   );
// }

function Images() {
  return (
    <section id="images" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Images</h2>
          <div className="subtitle">Our awesome students</div>
        </div>
        <Row className='portfoliolist'>
          {
            worksData.map(works => {
              return (
                <Col sm={4} key={works.id}>
                  <div className='portfolio-wrapper'>
                    <Image src={works.image} />
                  </div>
                </Col>
              );
            })
          }
        </Row>
        {/* <Pagination>{items}</Pagination> */}
      </Container>  
    </section>
  );
}

export default Images;