import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../../assets/images/czech.png'),
    title: 'Learn Czech',
    description: 'Learn Czech online. With Amal al Mustaqba courses, you can learn Czech easily and Online for decent price.',
    link: '#services'
  },
  {
    id: 2,
    image: require('../../assets/images/english.png'),
    title: 'Learn English',
    description: 'Learn English with us, grammar, vocabulary and reading activities. Practise your English and get ready for your university.',
    link: '#services'
  },
  {
    id: 3,
    image: require('../../assets/images/german.png'),
    title: 'Learn German',
    description: 'Want to learn German fast? We can help you learn to speak German quickly step-by-step, even if you are a complete beginner.',
    link: '#services'
  }
]

function CoursesHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p className='s-remove'>{hero.description}</p>
                    <a className="s-remove btn btn-primary" href={hero.link}>Learn More <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default CoursesHero;