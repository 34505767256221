import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import learnAll from '../../assets/images/all.png';

function AppAbout() {
  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row className="p-4">
          <Col sm={6}>
              <div className="w-100 h-100">
                <img alt="cz" src={learnAll} />
              </div>
          </Col>
          <Col sm={6}>
            <p>We are excited to announce our newest offering: three intensive language courses for students. These courses, provided by the language center, aim to enhance language skills in an immersive and comprehensive manner. The registration period for these courses will open soon. Designed for convenience and accessibility, the courses will be conducted online, enabling students to participate from anywhere.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;