import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';

var testimonialsData = [
  {
    id: 1,
    name: 'Nidal Abad',
    description: 'Thanks to the office staff for their good treatment, credibility and remarkable interest in our students even after their arrival in Prague.',
    designation: 'Student'
  },
  {
    id: 2,
    name: 'Adnan Musa',
    description: 'You were with me from the first step to the end. Thank you very much for your help. We advise all young people who want to study in Europe to contact you.',
    designation: 'Student'
  },
  {
    id: 3,
    name: 'Rasha Atrash',
    description: 'You deserve all the support. It is enough that you are the light of hope for this generation of young people to start a new path in their lives.',
    designation: 'Student Parent'
  }
]

function AppTestimonials() {
  return (
    <section id="testimonials" className="testimonials-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Client testimonials</h2>
          <div className="subtitle">what client says about us</div>
        </div>
        <Carousel controls={false}>
          {
            testimonialsData.map(testimonials => {
              return (
                <Carousel.Item key={testimonials.id}>
                  <blockquote>
                    <p>{testimonials.description}</p>
                    <cite>
                      <span className='name'>{testimonials.name}</span>
                      <span className='designation'>{testimonials.designation}</span>
                    </cite>
                  </blockquote>             
                </Carousel.Item>
              );
            })
          }
        </Carousel>
      </Container>
    </section>
  );
}

export default AppTestimonials;