import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const howItWorksData = [
  {
    id: 1,
    title: 'Academic Certificate',
    description: 'First, we will need a copy of your passport in addition to a copy of your latest academic certificate (in the beginning, it is sufficient without translation). Then, we will send you a request to fill out so that the university can prepare the contract and payment notice and send them to you in the form of a PDF file or receive them in person from our office.',
    link: 'https://www.google.com'
  },
  {
    id: 2,
    title: 'Sign The Contract',
    description: 'After you sign the contract with the university, pledge with the office, pay the fees to the office, and transfer the university tuition, which is 5300€, to the university bank account, the university admission procedures will begin immediately. The process takes two working weeks. By the end of the two weeks, we will also have prepared the university housing reservation for you and will send all the documents via the DHL express shipping office.',
    link: 'https://www.facebook.com'
  },
  {
    id: 3,
    title: 'Book An Appointment',
    description: 'After receiving the papers and preparing your documents in your country and translating them into Czech and authenticating them from the Ministry of Justice and Foreign Affairs (the certificate and the ruling only), we will also have booked an appointment for you at the embassy and begin preparing you intensively for the interview so that it is 100% successful. The final response from the embassy will be approximately 60 days after the interview.',
    link: 'https://www.twitter.com'
  }
]

function HowItWorks() {
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>How it wokrs?</h2>
          <div className="subtitle">get our latest news from blog</div>
        </div>
        <Row className='text-center'>
          {
            howItWorksData.map(blog => {
              return (
                <Col sm={4} key={blog.id}>
                  <div className='holder'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <time>{blog.time}</time>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                        {/* <a href={blog.link} className="btn btn-primary">Read More <i className="fas fa-chevron-right"></i></a> */}
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default HowItWorks;