import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import emailjs from '@emailjs/browser';
import logoEmirates from '../assets/logo/emirates.png';
import logoEurope from '../assets/logo/europe.png';
// import logoPrague from '../assets/logo/prague.png';

function AppContact() {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_qckwbs5', 'template_fydcc6u', e.target, 'GrkMRxBhKwuyKaKMr');
  }

  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">get connected with us</div>
        </div>
        <Form className='contact-form' onSubmit={sendEmail}>
          <Row>
            <Col sm={3}>
              <Form.Label className="form-label">First Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="First Name" required />
            </Col>
            <Col sm={3}>
              <Form.Label className="form-label">Last Name</Form.Label>
              <Form.Control type="text" name="lastname" placeholder="Last Name" required />
            </Col>
            <Col sm={3}>
              <Form.Label className="form-label">Email</Form.Label>
              <Form.Control type="email" name="email" placeholder="youremail@email.com" required />
            </Col>
            <Col sm={3}>
              <Form.Label className="form-label">Phone Number</Form.Label>
              <Form.Control pattern="^\+[0-9]+$" type="tel" name="number" placeholder="+963 000 000 000" required />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Label className="form-label">Age</Form.Label>
              <Form.Control pattern="^([1][7-9]|[2-2]\d|[3][2])$" type="text" name="age" placeholder="Only Between 17 & 32" required />
            </Col>
            <Col sm={4}>
              <Form.Label className="form-label">Certificate</Form.Label>
              <Form.Control type="text" name="certificate" placeholder="Certificate" required />
            </Col>
            <Col sm={4}>
              <Form.Label className="form-label">Graduation date</Form.Label>
              <Form.Control type="date" name="date" placeholder="Graduation date" required />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Label className="form-label">Your Message</Form.Label>
              <Form.Control as="textarea" name="message" placeholder="Enter your message here..." required />
            </Col>
          </Row>
          <div className='btn-holder'>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Container>
      <div className='google-map'>
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3632.4630457122485!2d54.42876237486657!3d24.434715162162473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6946019a0d09%3A0xa6ee2341ace96aa3!2z2KPZhdmEINin2YTZhdiz2KrZgtio2YQg2YTZhNiv2LHYp9iz2Kkg2YHZiiDYp9mE2K7Yp9ix2Kwg2YjYp9mE2YLYqNmI2YTYp9iqINin2YTYrNin2YXYudmK2KkgLyBBbWFsIEFsIE11c3RhcWJhbA!5e0!3m2!1sen!2scz!4v1727199616438!5m2!1sen!2scz"></iframe>
      </div>
      <Container fluid>
        <div className='contact-info'>
          <Row>
            <Col sm={6}>
              <h3 className='text-center fw-bold'>United Arab Emirates</h3>
            </Col>            
            <Col sm={6}>
              <img className='logo' alt="logo" src={logoEmirates} />
            </Col>
          </Row>
          <ul>
            <li className='m-1'>
              <i className="fas fa-envelope"></i>
              <a className='text-secondary' href= "mailto: abudhabi@amalalmustaqbal.com">abudhabi@amalalmustaqbal.com</a>
            </li>
            <li className='m-1'>
              <i className="fas fa-phone"></i>
              <a className='d-md-block text-secondary pe-pointer' href="tel:+971505036942">+971505036942 </a>
              <a className='d-md-block text-secondary pe-pointer' href="tel:+971505036253">+971505036253 </a>
              <a className='d-md-block text-secondary pe-pointer' href="tel:+97128585846">+97128585846 </a> 
            </li>
            <li className='m-1'>
              <i className="fas fa-map-marker-alt"></i>
              <a className='text-secondary' href="https://maps.app.goo.gl/t6v7G4LGzBJqp4vF9" target="_blank" rel="noopener noreferrer">10 Al Hamlah St - Al Sa'adah - Zone 1 - Abu Dhabi - United Arab Emirates</a>
            </li>
          </ul>
        </div>
        <hr/>
        <div className='contact-info'>
          <Row>
            <Col sm={6}>
              <h3 className='text-center fw-bold'>Syria</h3>
            </Col>            
            <Col sm={6}>
              <img className='logo' alt="logo" src={logoEurope} />
            </Col>
          </Row>
          <ul>
            <li className='m-1'>
              <i className="fas fa-envelope"></i>
              <a className='text-secondary' href= "mailto: study-prague@seznam.cz">study-prague@seznam.cz</a>
            </li>
            <li className='m-1'>
              <i className="fas fa-phone"></i>
              <a className='d-md-block text-secondary pe-pointer' href="tel:+963995629837">+963995629837 </a>
              <a className='d-md-block text-secondary pe-pointer' href="tel:+963957941019">+963957941019 </a>
            </li>
            <li className='m-1'>
              <i className="fas fa-map-marker-alt"></i>
               <a className='text-secondary' href="https://maps.app.goo.gl/uoX8mTdF5dUyZKnq5" target="_blank" rel="noopener noreferrer">السويداء, برج الأمير خلف الملعب البلدي بجانب العيادات الشاملة ط 3<br/>As Suwayda, Syria</a>
            </li>
          </ul>
        </div>
        <hr/>
        <div className='contact-info'>
         <Row> 
            <Col sm={6}>
              <h3 className='text-center fw-bold'>Czechia - Prague</h3>
            </Col>            
            <Col sm={6}>
              <img className='logo' alt="logo" src={logoEurope} />
            </Col>
          </Row>
          <ul>
            <li className='m-1'>
              <i className="fas fa-envelope"></i>
              <a className='text-secondary' href= "mailto: study-prague@seznam.cz">study-prague@seznam.cz</a>
            </li>
            <li className='m-1'>
              <i className="fas fa-brands fa-whatsapp"></i>
              <a className='d-md-block text-secondary pe-pointer' href="tel:+420608561886">+420608561886</a>
            </li>
            <li className='m-1'>
              <i className="fas fa-map-marker-alt"></i>
              Praha-1 , 110 00
            </li>
          </ul>
        </div>   
        <hr/>     
        <div className='contact-info'>
          <h3 className='text-center fw-bold'>Social Media</h3>
          <ul>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100087677912115" target="_blank" rel="noopener noreferrer">
                <i className="fab fas fa-facebook-f"></i>
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/amal.almustaqbal" target="_blank" rel="noopener noreferrer">
                <i className="fab fas fa-instagram"></i>
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;