import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './components/home';
import Courses from './components/courses/courses';
import AppHeader from './components/header';
import AppFooter from './components/footer';

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header id='header'>
        <AppHeader />
      </header>
      <main>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
        </Routes>
      </BrowserRouter>
      </main>
      <footer id="footer">
        <AppFooter />
      </footer>
    </div>
  );
}

export default App;