import CoursesHero from './courses-hero';
import AppAbout from './courses-about';
import AppTestimonials from './courses-testimonials';
import AppContact from './courses-contact';
import AppServices from './courses-services';
import HowItWorks from './courses-howItWorks';
import Images from './courses-images';

function Courses() {
  return (
      <>
        <CoursesHero />
        <AppAbout />
        <AppServices />
        <HowItWorks />
        <Images />
        <AppTestimonials />
        <AppContact />
      </>
  );
}

export default Courses;